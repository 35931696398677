import React from "react";

import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import Layout from "../components/layout";
import Container from "../common/components/container";
import Dashboard from "../components/dashboard";

// http://gpwidget-guides-prod.s3-website.eu-west-2.amazonaws.com/
//http://gpwidget-app-prod.s3-website.eu-west-2.amazonaws.com/

function Index({ user }) {
  return (
    <div>
      <Layout>
        <Container>
          <Dashboard />
        </Container>
      </Layout>
    </div>
  );
}

export default Index;
