import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchStats, addStat } from "../../actions";

const Index = () => {
  const stats = useSelector((state) => state.stats);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchStats());
  }, []);

  function addStatFunction(data) {
    console.log("add stat");
    dispatch(addStat({ action: "this" }));
  }

  function returnStats() {
    return stats.map((stat) => {
      return <div>{stat.action}</div>;
    });
  }
  return (
    <div>
      <div>Dashboard - Stats</div>
      <div>
        <div onClick={() => addStatFunction()}>Add stat</div>
        <div>{returnStats()}</div>
      </div>
    </div>
  );
};

export default Index;
